import React from "react"
import theme from "theme"
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Início | Bem-vindo à Ace Team Ténis & Padel</title>
        <meta
          name={"description"}
          content={"Sirva-o, esmague-o, ganhe-o, ame-o!"}
        />
        <meta
          property={"og:title"}
          content={"Início | Bem-vindo à Ace Team Ténis & Padel"}
        />
        <meta
          property={"og:description"}
          content={"Sirva-o, esmague-o, ganhe-o, ame-o!"}
        />
        <meta
          property={"og:image"}
          content={"https://zixolate.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://zixolate.com/img/14572457.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://zixolate.com/img/14572457.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://zixolate.com/img/14572457.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://zixolate.com/img/14572457.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://zixolate.com/img/14572457.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://zixolate.com/img/14572457.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="0 0 0 0"
        quarkly-title="Hero-7"
        background="--color-darkL2"
      >
        <Override
          slot="SectionContent"
          width="100%"
          flex-direction="row"
          max-width="100%"
        />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          justify-content="space-around"
          lg-flex-direction="column"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            padding="120px 50px 120px 50px"
            lg-width="100%"
            lg-padding="80px 50px 90px 50px"
            sm-padding="80px 25px 90px 25px"
            lg-display="flex"
            lg-flex-direction="column"
          >
            <Text
              margin="0px 0px 20px 0px"
              color="--light"
              font="normal 700 56px/1.2 --fontFamily-sans"
              sm-font="normal 700 42px/1.2 --fontFamily-sans"
            >
              Bem-vindo à Ace Team Ténis & Padel
            </Text>
            <Text margin="0px 0px 50px 0px" font="--lead" color="--light">
              Na Ace Team Ténis & Padel, compreendemos a alegria do jogo. É por
              isso que dedicamos os nossos campos a todos os entusiastas do
              ténis - desde os jogadores casuais até aos profissionais
              experientes. Com as nossas instalações de topo, não pode esperar
              nada menos do que uma experiência de excelência.
            </Text>
            <Box
              min-width="10px"
              min-height="10px"
              display="flex"
              sm-flex-direction="column"
              sm-align-items="stretch"
            >
              <Button
                margin="0px 15px 0px 0px"
                padding="12px 28px 12px 28px"
                background="--color-red"
                border-radius="8px"
                font="normal 400 17px/1.5 --fontFamily-sans"
                sm-margin="0px 0 15px 0px"
                transition="background-color 0.2s ease 0s"
                border-width="2px"
                border-style="solid"
                border-color="--color-red"
                hover-color="--darkL1"
                hover-background="rgba(63, 36, 216, 0)"
                href="/contacts"
                type="link"
                text-decoration-line="initial"
              >
                Contate-nos
              </Button>
            </Box>
          </Box>
          <Image
            src="https://zixolate.com/img/1.jpg"
            display="block"
            width="50%"
            object-fit="cover"
            height="100%"
            lg-width="100%"
            lg-height="700px"
            sm-height="500px"
          />
        </Box>
      </Section>
      <Section
        padding="80px 0 80px 0"
        background="#EDF2F6"
        sm-padding="60px 0 60px 0"
      >
        <Box
          width="100%"
          display="flex"
          flex-direction="column"
          md-width="100%"
          md-align-items="center"
          md-justify-content="center"
          md-text-align="center"
          lg-width="100%"
          margin="0px 0px 56px 0px"
          align-items="center"
        >
          <Text
            margin="0px 0px 16px 0px"
            font="--headline2"
            color="--dark"
            md-text-align="center"
            sm-font='normal 700 32px/1.2 "Source Sans Pro", sans-serif'
            text-align="center"
          >
            Porquê jogar aqui?
          </Text>
        </Box>
        <Box
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="32px 4%"
          md-grid-template-columns="1fr"
        >
          <Box
            padding="45px 45px"
            lg-padding="45px 30px"
            md-padding="45px 45px"
            background="#FFFFFF"
            border-radius="24px"
            justify-content="flex-start"
            flex-direction="column"
            display="flex"
          >
            <Text
              margin="0px 0px 18px 0px"
              color="--darkL2"
              font="--headline3"
              lg-text-align="left"
            >
              Condições impecáveis dos campos
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD3"
              font="--base"
              lg-text-align="left"
              flex="1 0 auto"
            >
              Superfícies lisas e uniformes que melhoram a jogabilidade.
            </Text>
          </Box>
          <Box
            padding="45px 45px"
            lg-padding="45px 30px"
            md-padding="45px 45px"
            background="#FFFFFF"
            border-radius="24px"
            justify-content="flex-start"
            flex-direction="column"
            display="flex"
          >
            <Text
              margin="0px 0px 18px 0px"
              color="--darkL2"
              font="--headline3"
              lg-text-align="left"
            >
              Aluguer de equipamento
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD3"
              font="--base"
              lg-text-align="left"
              flex="1 0 auto"
            >
              Raquetes e bolas de alta qualidade para todos os níveis de
              habilidade.
            </Text>
          </Box>
          <Box
            padding="45px 45px"
            lg-padding="45px 30px"
            md-padding="45px 45px"
            background="#FFFFFF"
            border-radius="24px"
            justify-content="flex-start"
            flex-direction="column"
            display="flex"
          >
            <Text
              margin="0px 0px 18px 0px"
              color="--darkL2"
              font="--headline3"
              lg-text-align="left"
            >
              Opções de formação profissional
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD3"
              font="--base"
              lg-text-align="left"
              flex="1 0 auto"
            >
              Melhore o seu jogo com as nossas sessões de treino disponíveis.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section
        padding="100px 40px 100px 40px"
        background="--color-light"
        sm-padding="40px 20px 40px 20px"
        quarkly-title="Images-24"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          grid-gap="20px"
          flex-wrap="wrap"
          max-height="1200px"
          max-width="1280px"
          align-content="flex-start"
          lg-flex-direction="column"
          lg-flex-wrap="no-wrap"
          lg-width="100%"
          lg-max-width="none"
          lg-align-items="flex-end"
          sm-min-width="280px"
        />
        <Image
          src="https://zixolate.com/img/2.jpg"
          display="block"
          width="40%"
          lg-order="-1"
          sm-width="100%"
        />
        <Text
          color="--darkL2"
          margin="0px 0px 0px 0px"
          font="--headline2"
          width="35%"
          text-align="left"
          order="-1"
          lg-width="100%"
          lg-font="--headline3"
          sm-margin="16px 0px 0px 0px"
        >
          Serviço Final
        </Text>
        <Text
          color="--darkL2"
          margin="0px 0px 0px 0px"
          font="--lead"
          width="20%"
          lg-width="100%"
          lg-max-width="540px"
        >
          Reserve o seu campo hoje e experimente a diferença da Ace Team. Onde
          cada serviço é espetacular e cada jogo é um grand slam. Contacte-nos
          hoje e entre já em ação - O seu campo espera-o!
        </Text>
      </Section>
      <Components.Contact />
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
